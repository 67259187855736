<template>
  <div @click.stop="close">
    <button
      type="button"
      class="btn btn-primary"
      id="button-open-dialog"
      data-bs-toggle="modal"
      data-bs-target="#kt_modal_1"
      hidden
    ></button>
    <div class="modal fade" tabindex="-1" id="kt_modal_1">
      <div class="modal-dialog modal-xl">
        <div class="modal-content pb-3">
          <div class="modal-header d-flex justify-content-between">
            <h5 class="modal-title">เลือกต้นทาง / ผู้โอนสินค้า</h5>
            <button
              data-bs-dismiss="modal"
              @click="close"
              type="button"
              class="btn btn-sm"
              id="closeBtn"
            >
              <i
                class="bi bi-x m-0 p-0"
                id="close-btn"
                style="font-size: 25px"
              ></i>
            </button>
          </div>

          <div class="modal-body pb-0">
            <div class="row d-flex justify-content-center mb-4">
              <div class="col-sm-9">
                <input
                  v-model="searchInput"
                  placeholder="🔍 พิมพ์คำค้นหา"
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
              <div class="col-sm-1 text-end">
                <button
                  @click="search"
                  type="button"
                  class="btn btn-light-primary"
                  style="font-size: 14px; font-weight: bold"
                >
                  ค้นหา
                </button>
              </div>
            </div>

            <ul class="nav nav-tabs nav-line-tabs mb-5 fs-5">
              <li
                class="nav-item"
                style="width: 80px"
                @click="selectedNav = false"
              >
                <a
                  id="hover-nav"
                  class="nav-link active text-center m-0 p-2"
                  :style="!selectedNav ? 'color: DodgerBlue' : ''"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_1"
                  >ทั้งหมด</a
                >
              </li>
              <li
                class="nav-item"
                style="width: 80px"
                @click="selectedNav = true"
              >
                <a
                  id="hover-nav"
                  class="nav-link text-center m-0 p-2"
                  :style="selectedNav ? 'color: DodgerBlue' : ''"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_2"
                  >คู่ค้า</a
                >
              </li>
            </ul>

            <div class="card" id="kt_tab_pane_1" v-if="!selectedNav">
              <table
                class="table table-hover table-rounded table-striped border gy-7 gs-7"
              >
                <thead>
                  <tr
                    class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200"
                  >
                    <th width="25%">รหัสผู้รับโอน</th>
                    <th width="50%">ชื่อผู้รับโอน</th>
                    <th width="50%">สาขา</th>
                    <th width="25%">เบอร์โทรศัพท์</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style="cursor: pointer"
                    v-for="(item, index) in dataItemsShow"
                    :key="index"
                    @click="selectedItem(item)"
                  >
                    <td>{{ item.transferee_code }}</td>
                    <td>{{ item.transferee_name }}</td>
                    <td>{{ item.transferee_branch }}</td>
                    <td>{{ item.transferee_tel }}</td>
                    <!-- <td>{{ item.vendor_id_card }}</td> -->
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="card" id="kt_tab_pane_2" v-if="selectedNav">
              <table
                class="table table-hover table-rounded table-striped border gy-7 gs-7"
              >
                <thead>
                  <tr
                    class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200"
                  >
                    <th width="25%">รหัสผู้รับโอน</th>
                    <th width="50%">ชื่อผู้รับโอน</th>
                    <th width="50%">สาขา</th>
                    <th width="25%">เบอร์โทรศัพท์</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in dataItemsShow" :key="index">
                    <td>{{ item.transferee_code }}</td>
                    <td>{{ item.transferee_name }}</td>
                    <td>{{ item.transferee_branch }}</td>
                    <td>{{ item.transferee_tel }}</td>
                    <!-- <td>{{ item.vendor_id_card }}</td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <Pagination
            :items="tableItems"
            @filterItems="filterItems"
            @handleChangePage="handleChangePage"
          />

          <!-- <div class="modal-footer d-flex justify-content-start">
            <hr class="pt-0 mt-0" style="color: LightGrey" />

            <footer>
              <div class="card-toolbar">
                <button
                  @click="submit"
                  type="button"
                  class="btn btn-primary me-3"
                  data-bs-dismiss="modal"
                >
                  บันทึก
                </button>
                <button
                  @click="close"
                  type="button"
                  class="btn btn-light"
                  data-bs-dismiss="modal"
                >
                  ยกเลิก
                </button>
              </div>
            </footer>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import transferApi from "@/api/transfer";
import Pagination from "../../../components/pagination.vue";

export default {
  components: { Pagination },
  props: {
    dialogSalesman: Boolean,
  },
  data: () => ({
    isSubmit: false,
    selectedNav: false,

    dataItemsShow: [],
    tableItems: [],
    showing1: null,

    searchInput: "",
  }),
  watch: {
    dialogSalesman(val) {
      if (val) {
        this.getTransferee();
        document.getElementById("button-open-dialog").click();
      }
    },
  },
  methods: {
    async getTransferee() {
      this.loading = true;
      let responseData = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      try {
        responseData = await transferApi.transferee.getAll({
          companyId: companyId,
          companyBranchId: companyBranchId,
        });
      } catch (error) {
        console.log(error);
      }
      console.log({ responseData });
      if (responseData.response_status === "SUCCESS") {
        this.dataItemsShow = responseData.data;
        this.tableItems = responseData.data;
        this.loading = false;
      }
    },
    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end
    submit() {
      this.close();
    },
    close() {
      this.$emit("closeDialogSalesman");
    },
    selectedItem(item) {
      this.$emit("closeDialogSalesman");
      document.getElementById("closeBtn").click();
      this.$emit("selectedItem", item);
    },

    async search() {
      this.loading = true;
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      const responseSearch = await transferApi.transferee.search(
        {
          search: this.searchInput,
        },
        {
          companyId: companyId,
          companyBranchId: companyBranchId,
        }
      );

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .display-phone {
    text-align: left !important;
  }
}
#close-btn:hover {
  background-color: LightGrey;
  border-radius: 25px;
  color: white;
}
#hover-nav:hover {
  background-color: WhiteSmoke;
}
</style>

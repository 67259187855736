import axios from "../instance";
const companyId = localStorage.getItem("companyId");
import addQuery from "../../utils/addQuery";

export default {
  getAll: async (payload) => {
    const result = await addQuery(payload);

    // return axios.api
    //   .get(
    //     `/transferProduct?transfer_type=${transfer_type}&status=${status.status}&status=${status.status1}&companyId=${companyId}`
    //   )
    //   .then((response) => response.data);
    return axios.api
      .get(`/transferProduct?${result}`)
      .then((response) => response.data);
  },
  getOne: (id) => {
    return axios.api
      .get(`/transferProduct/${id}`)
      .then((response) => response.data);
  },
  search: async (body, payload) => {
    const result = await addQuery(payload);

    return axios.api
      .post(`/transferProduct/search?${result}`, body)
      .then((response) => response.data);
  },
  create: (body) => {
    return axios.api
      .post("/transferProduct", body)
      .then((response) => response.data);
  },
  update: (id, body) => {
    return axios.api
      .put(`/transferProduct/${id}`, body)
      .then((response) => response.data);
  },
  delete: (id) => {
    return axios.api
      .delete(`/transferProduct/${id}`)
      .then((response) => response.data);
  },
};
